<template>
  <div class="about">
    <b-img
      :src="require('../../assets/about_banner.jpg')"
      fluid-grow
      alt="Responsive image"
    ></b-img>
    <!-- <div class="carousel_box">
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="4000"
        indicators
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333"
      >
        
        <b-carousel-slide
          img-src="https://picsum.photos/1024/480/?image=52"
        ></b-carousel-slide>

       
        <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=54">
        </b-carousel-slide>

        
        <b-carousel-slide
          img-src="https://picsum.photos/1024/480/?image=58"
        ></b-carousel-slide>
      </b-carousel>
    </div> -->
    <div class="problem_box">
      <b-container class="bv-example-row" fluid>
        <b-row>
          <b-col style="background-color: #f0f0f0" class="mb-4">
            <!-- :img-src="require('../../assets/product/zhihuiyunwei.png')" -->
            <b-card
              title="关于小鲲"
              img-alt="Image"
              img-bottom
              style="border: 0; text-align: center; background-color: #f0f0f0"
              class="wow fadeInUp"
            >
              <b-card-text
                class="wow fadeInUp"
                style="text-align: left; text-indent: 2em; line-height: 50px"
              >
                小鲲智能技术（广州）有限公司是一家专业面向环保领域AIOT数智化技术服务的企业，围绕工艺运行智能化、业务流程信息化、系统管控精细化的研发制造为核心的高新技术企业。为诸多知名环保企业提供基于物联网，云计算和人工智能应用的整体软硬件解决方案。
              </b-card-text>
              <b-card-text
                class="wow fadeInUp"
                style="text-align: left; text-indent: 2em; line-height: 50px"
              >
                公司设立了研发团队、解决方案设计团队和客户服务团队，拥有20多项发明、实用新型专利及软件著作权专利。
              </b-card-text>
              <b-card-text
                class="wow fadeInUp"
                style="text-align: left; text-indent: 2em; line-height: 50px"
              >
                公司已完成基于<span style="color: #0099ba"
                  >IOT和云计算的“分散式环保设备运维管理系统”</span
                >和<span style="color: #0099ba"
                  >基于图像识别和AI技术的“工业污水领域精准加药系统”</span
                >的软硬件等具有自主知识产权的产品的研制，在小型一体化污水处理（村庄、办公楼、商场、学校、医院、高速公路等）、VOCs气体处理、造纸污水、电镀污水、印染污水等多个领域得到应用。
              </b-card-text>
              <b-card-text
                class="wow fadeInUp"
                style="text-align: left; text-indent: 2em; line-height: 50px"
              >
                应用场景涵盖农村污水设施管理，污水处理设备、VOC气体设备、垃圾餐厨、管网泵站、环境异常监测、精准加药、精准曝气，水环境综合治理解决方案等环境服务产业链。
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="problem_box_s">
          <b-col
            style="background-color: #f0f0f0"
            lg="4"
            md="12"
            sm="12"
            xs="auto"
            class="mb-4"
          >
            <b-card
              :img-src="require('../../assets/about/xk_zhyw.jpg')"
              title="小鲲智能运维管理平台"
              img-alt="Image"
              img-top
              tag="article"
              title-tag="h5"
              img-height="320px"
              style="border: 0; background-color: #f0f0f0"
              class="wow fadeInUp"
            >
              <b-card-text class="small text-muted"
                >赋能行业用户 助力产业升级</b-card-text
              >
            </b-card>
          </b-col>
          <b-col
            style="background-color: #f0f0f0"
            lg="8"
            md="12"
            sm="12"
            xs="auto"
            class="mb-4"
          >
            <b-card
              :img-src="require('../../assets/about/ceo.jpg')"
              title="小鲲智能技术刘稚鹏"
              img-alt="Image"
              img-top
              tag="article"
              title-tag="h5"
              img-height="320px"
              style="border: 0; background-color: #f0f0f0"
              class="wow fadeInUp"
            >
              <b-card-text class="small text-muted"
                >新基建下的环保设备智慧物联技术变革</b-card-text
              >
            </b-card>
          </b-col>
          <b-col
            style="background-color: #f0f0f0"
            lg="4"
            md="12"
            sm="12"
            xs="auto"
            class="mb-4"
          >
            <b-card
              :img-src="require('../../assets/about/xk_nw.jpg')"
              title="农村污水处理设备运维智能化"
              img-alt="Image"
              img-top
              tag="article"
              title-tag="h5"
              img-height="320px"
              style="border: 0; background-color: #f0f0f0"
              class="wow fadeInUp"
            >
            </b-card>
          </b-col>
          <b-col
            style="background-color: #f0f0f0"
            lg="4"
            md="12"
            sm="12"
            xs="auto"
            class="mb-4"
          >
            <b-card
              :img-src="require('../../assets/about/xk_jzjy.jpg')"
              title="污水处理精准加药"
              img-alt="Image"
              img-top
              tag="article"
              title-tag="h5"
              img-height="320px"
              style="border: 0; background-color: #f0f0f0"
              class="wow fadeInUp"
            >
            </b-card>
          </b-col>
          <b-col
            style="background-color: #f0f0f0"
            lg="4"
            md="12"
            sm="12"
            xs="auto"
            class="mb-4"
          >
            <b-card
              title="工业园区智慧管控"
              :img-src="require('../../assets/about/xk_gyyq.jpg')"
              img-alt="Image"
              img-top
              tag="article"
              title-tag="h5"
              img-height="320px"
              style="border: 0; background-color: #f0f0f0"
              class="wow fadeInUp"
            >
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- <Footers /> -->
  </div>
</template>
<script>
import { WOW } from "wowjs";
// import Footers from "../../components/footers.vue";
export default {
  data() {
    return {
      slide: 0,
    };
  },
  metaInfo() {
    return {
      title: "小鲲智能技术(广州)有限公司-关于我们",
      meta: [
        {
          name: "keywords",
          content: "小鲲智能,小鲲智能技术(广州)有限公司,小鲲智能技术广州有限公司,关于小鲲",
        },
        {
          name: "description",
          content: "小鲲智能技术（广州）有限公司是一家专业面向环保领域AIOT数智化技术服务的企业，围绕工艺运行智能化、业务流程信息化、系统管控精细化的研发制造为核心的高新技术企业。",
        },
      ],
    };
  },
  created() {
    scrollTo(0, 0);
  },
  mounted() {
    new WOW({ live: false }).init();
  },
  // components: {
  //   Footers,
  // },
};
</script>
<style lang="less" scoped>
.about {
  width: 100%;
  height: auto;
  .problem_box {
    width: 100%;
    height: auto;
    margin: 0 auto;
    box-shadow: 0 0 5px #f0f0f0 inset;
    padding: 20px 160px 20px 160px;
    background-color: #f0f0f0;
    .card-body {
      // padding: 0;
      .card-title {
        font-size: 26px;
      }
    }

    .problem_box_s {
      padding: 0 30px;
      box-sizing: border-box;
      .card-body {
        height: 65px;
        padding: 10px 20px;
        cursor: pointer;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        // background-color: rgba(0, 0, 0, 0.445);
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.541), rgba(0, 0, 0, 0));
        .card-title {
          font-size: 20px;
          color: #fff;
          margin-bottom: 5px !important;
        }
        .text-muted {
          color: #f0f0f0 !important;
        }
      }
      // .card-img-top {
      //   // &:hover {
      //   //   transform: scale(1.03);
      //   //   transition: all 0.3s linear;
      //   // }
      // }
    }
  }
  @media screen and (min-width: 375px) and (max-width: 992px) {
    .problem_box {
      padding: 40px 20px 40px 20px;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1550px) {
    .problem_box {
      padding: 40px 50px 40px 50px;
    }
  }
  @media screen and (max-width: 375px) {
    .problem_box {
      padding: 40px 0 40px 0;
    }
  }
  .card-img-top {
    object-fit: cover;
  }
}
</style>
